/* Lobby.css */
.player-list-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.player-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  overflow-y: auto;
  max-height: 60vh;
}

.player-name {
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
  margin: 0.5rem;
  animation: pop-in 0.5s cubic-bezier(0, 1.2, 0.8, 1) 1;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
