@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* .grid-pattern {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 20% 20%;  /* Updated from 10% to 20% */
/* } */
.grid-pattern {
  background-image: 
    linear-gradient(0deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 25% 20%;  /* Adjusted to 25% horizontally and 20% vertically */
}

@media (max-width: 640px) {
  .heatmap-container {
    width: calc(100vw - 4rem);
    height: calc(100vw - 4rem);
  }
  .left-axis {
    left: -1rem;
  }
}